import React, { Component } from "react"
import SEO from "../components/seo"
import GridItem from "../components/grid-item"
import { BodyCopy, Standfirst, Service, Heading, Services } from "../components/styles/textStyles"

class PortfolioPage extends Component {
  state = {
    activeService: 1
  }

  handleClick = activeService => {
    this.setState({ activeService });
  }

  render() {
    const { activeService } = this.state;

    return (
      <>
        <SEO title="Portfolio Of Services" />
        <GridItem start="3" end="7">
          <Standfirst>Strategic real estate advice <br/>for every circumstance.</Standfirst>
        </GridItem>
        <GridItem start="3" end="7">
          <Heading>Portfolio of Services</Heading>
        </GridItem>
        <GridItem start="3" end="5" tabletEnd="7">
          <BodyCopy>
            <Services activeService={activeService}>
              <Service onClick={() => this.handleClick(1)}><span>Marketability Studies</span></Service>
              <Service onClick={() => this.handleClick(2)}><span>Property &amp; Portfolio Valuations</span></Service>
              <Service onClick={() => this.handleClick(3)}><span>Investment Advisory Services</span></Service>
              <Service onClick={() => this.handleClick(4)}><span>Development Advisory Services</span></Service>
              <Service onClick={() => this.handleClick(5)}><span>Financial Feasibility</span></Service>
              <Service onClick={() => this.handleClick(6)}><span>Workouts And Deal Structuring</span></Service>
              <Service onClick={() => this.handleClick(7)}><span>Arbitrations</span></Service>
              <Service onClick={() => this.handleClick(8)}><span>Litigation And Expert Testimony</span></Service>
              <Service onClick={() => this.handleClick(9)}><span>Economic and Fiscal Impact Analysis</span></Service>
              <Service onClick={() => this.handleClick(10)}><span>Public-Private Partnership Services</span></Service>
              <Service onClick={() => this.handleClick(11)}><span>Marketing Strategy</span></Service>
            </Services>
          </BodyCopy>
        </GridItem>
        <GridItem start="5" end="7" tabletStart="3" tabletEnd="7">
          <BodyCopy>
            {activeService === 1 && <p>We prepare site specific studies measuring market health and depth, analyzing the competitive landscape, identifying the target markets, and recommending the achievable revenue assumptions and product features.</p>}
            {activeService === 2 && <p>Known for our expertise with complex and unusual assets, we value institutional grade property, developments, land and investment positions in every market.</p>}
            {activeService === 3 && <p>We serve as advisors to major investment funds, family offices, and private developers to inform a sound real estate investment strategy with both market research and financial underwriting.</p>}
            {activeService === 4 && <p>Stakeholders in development projects rely on us to help them conceptualize the most effective strategy to build a uniquely marketable and financially viable project, or to help put an existing development back on track.</p>}
            {activeService === 5 && <p>We independently model the prospective financial performance of an investment or development, analyzing multiple facets of sensitivity in order to forecast a project’s risk and return.</p>}
            {activeService === 6 && <p>We are experts in structuring creative and pragmatic deal terms, forming new property-level strategies, and building consensus among stakeholders as an independent consultant in workout and new partnership situations.</p>}
            {activeService === 7 && <p>Serving as an arbitrator or testifying as an expert witness, we have significant expertise in proceedings related to ground rent determinations, market rent resets, valuation issues, and partial interests.</p>}
            {activeService === 8 && <p>We frequently provide expert testimony in proceedings including bankruptcy, divorce, family and partnership disputes, condemnation and other matters, on topics ranging from valuation to marketability.</p>}
            {activeService === 9 && <p>We conduct economic and fiscal impact analyses demonstrating the one-time and ongoing effects (such as new jobs and fiscal revenue streams) generated by real estate development projects and policy proposals that must be communicated to a variety of audiences.</p>}
            {activeService === 10 && <p>We assist private real estate entities and governments with structuring partnerships to unlock the value and economic development potential of property holdings that require collaboration between businesses and the public sector.</p>}
            {activeService === 11 && <p>Developers rely on us to create and implement strategies that enhance project marketability, combining our deep experience in product conceptualization, marketing, and sales with our knowledge of property operations, design, and financial feasibility.</p>}
          </BodyCopy>
        </GridItem>
      </>
    )
  }
}

export default PortfolioPage
